<div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>     
  <h3> Service List </h3>
  <form [formGroup]="addservicelist">
      <div class="login-form comn_frm ad_st">
        <div class="form-group">
            <label class="control-label">Category </label>
            <mat-form-field floatLabel="never">
                <mat-select formControlName="serviceCategory" placeholder="Please Select">
                    <mat-option *ngFor="let cat of secondLevel " [value]="cat._id">
                        {{ cat?.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf=" submitted && f.serviceCategory.errors?.required">
                    Required
                </mat-error>
            </mat-form-field>
        </div>
          
          <div class="form-group">
              <label class="control-label">Service Name</label>
              <input type="text" id="name"  class="form-control" formControlName="name"/>
              <mat-error *ngIf=" submitted && f.name.errors?.required">
                Required
            </mat-error>
          </div>
          <div class="form-group">
            <label class="control-label">Service Name(Ar)</label>
            <input type="text" id="name"  class="form-control" formControlName="name_Ar"/>
            <mat-error *ngIf=" submitted && f.name_Ar.errors?.required">
                Required
            </mat-error>
        </div>
        
  
  
        <div class="btn_main cnt" *ngIf="!data" >
            <button class="btn btn-primary" type="button" (click)="add()"> Add</button>
          </div>
          <div class="btn_main cnt" *ngIf="data">
            <button class="btn btn-primary" type="button" (click)="update()"> Update</button>
          </div>
      </div>
  
  
  </form>
  </div>