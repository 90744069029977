import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnInit {
  data:any;
  commission:string;
  submitted:boolean = false;
  constructor(private dialog: MatDialog,private http:HttpService,private cm:CommonService) { }
  close(){
    this.dialog.closeAll()
  }

  ngOnInit(): void {  
    if(this.data){
      this.commission = this.data.commission; 
    }
  }

  update(){
    this.submitted= true; 
    if(this.submitted && this.commission){
      let data1;
      data1 = {
        commission:Number(this.commission)
      }
      this.http.putRequest('admin/type/' + this.data._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Subscription fees update successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }
}
