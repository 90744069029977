import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-courier-name',
  templateUrl: './courier-name.component.html',
  styleUrls: ['./courier-name.component.scss']
})
export class CourierNameComponent implements OnInit {

  url:any
  data:any
  couriertypeForm:FormGroup
  submitted1:boolean = false;

  constructor(private dialog: MatDialog,
    private http:HttpService,
    private fb:FormBuilder,
    private cm:CommonService,
    private router:Router) {
    this.createForm();
   }

  ngOnInit(): void {
    if(this.data){
      this.setValue();
    }
  
  }
  setValue(){
    this.couriertypeForm.patchValue(this.data)
  
  }

  createForm(){
    this.couriertypeForm = this.fb.group({
      name: ['', [Validators.required,Validators.maxLength(30)]],
    });
  }

  close(){
    this.dialog.closeAll()

  }

  add(){
    this.submitted1 = true;
    console.log("vendor",this.couriertypeForm);
    
    if(this.submitted1 && this.couriertypeForm.valid){
      let data1 = this.couriertypeForm.value
      data1.type = 'COURIER_NAME'
      this.http.postRequest('admin/vendor/settings',data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Shipping Type Added successfully');
          this.dialog.closeAll()
          this.cm.vendorSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );
      }
  }

  update(){
    this.submitted1 = true;
    console.log("vendor",this.couriertypeForm);
    
    if(this.submitted1 && this.couriertypeForm.valid){
      let data1 = this.couriertypeForm.value
      data1.type = 'COURIER_NAME'
      this.http.putRequest('admin/vendor/settings/' + this.data?._id,data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Shipping Type updated successfully');
          this.dialog.closeAll()
          this.cm.vendorSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );
      }
  }

}
