

      <div class="sze_cls">
        <div class="sze_c_mn">
          <h2>Select Product</h2>
        </div>
        <div class="card">
          <div class="card-body">
            
            <div class="table-responsive">
              <table>
                <tr>
                  <th>Select</th>               
                  <th>Thumbnail</th>
                  <th>Product Name
                    <div class="srch_fl">
                      <mat-form-field appearance="fill">
                        <input matInput class="form-field" placeholder="Search By Product Name"
                          (keyup)="searchUnique($event.target.value,'productName')">
                      </mat-form-field>
                    </div>
                  </th>  
                  <th>Sku
                    <div class="srch_fl">
                      <mat-form-field appearance="fill">
                        <input matInput class="form-field" placeholder="Search By Sku"
                          (keyup)="searchUnique($event.target.value,'sku')">
                      </mat-form-field>
                    </div>
                  </th>               
                  <th>Brand
                    <div class="srch_fl">
                      <mat-form-field appearance="fill">
                        <input matInput class="form-field" placeholder="Search By Brand"
                          (keyup)="searchUnique($event.target.value,'brand')">
                      </mat-form-field>
                    </div>
                  </th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Stock</th>
                  <th >Color</th>                
                  <th >Size</th>                 
                </tr>
                <tr *ngFor="let data of storeProductData; let i = index">
                <td>
                    <mat-radio-button value="true" (change)="selectRadio($event,data)"></mat-radio-button>
                </td>
                
                  <td class="img_usr">            
                    <figure
                      *ngIf="data?.variations.child?.images?.length>0"
                    >                   
                      <img
                        [src]="
                          data?.variations?.child?.images[0]?.path
                            ? data?.variations?.child?.images[0]?.path
                            : 'assets/img/png/No_image_available.png'
                        "
                        alt=""
                      />
                     </figure>
                  </td>
                  <td >
                    {{ data?.productName || "---" }}
                  </td>
                  <td >
                    {{ data?.variations?.child?.sku || "---" }}
                  </td>
                  <td>
                    {{ data?.brand?.name || "----" }}
                  </td>
                  <td >
                    {{ data?.primaryCategory?.name || "----" }}
                  </td>
                  <td>
                    {{
                      data?.variations?.child?.price
                        ? data?.variations?.child?.price
                        : "----"
                    }}
                  </td>
                  <td>
                    {{ data?.variations?.child?.stock || "----" }}
                  </td>                 
                  <td >
                   {{data?.variations?.attributeValue}}
                  </td>               
                  <td >
                    {{data?.variations?.child?.attributeValue}}
                  </td>               
                </tr>
              </table>
            </div>
          </div>
          
        </div>
        <div class="btn_main">
          <button class="btn btn-primary" type="button" (click)="addproduct()">Submit</button>
          <button class="btn btn-primary" style="margin-left: 10px;" type="button" (click)="close()">Close</button>
        </div>
      </div>
  