
  <div class="container-fluid">
    <div class="customer-page">
     
      <div class="card">
        <div class="card-body">
          <!-- <div class="search-bar srch_br"> -->
            
              <h2>Staff Appointment</h2>
         
          <!--              
              <div class="loginform">
  
                <div class="form-group srch_int">
  
                  <app-search (searchOutput)="fetchSearchOutput($event)"></app-search>
  
                </div>
             
              </div> -->

          <!-- <div class="pagntn_cls">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <app-paginator [recordsLength]="totalItems" [pageSize]="pageSize"
                      (paginationOutput)="fetchOutputPagination($event)">
                    </app-paginator>
  
                  </ul>
                </nav>
              </div> -->
          <!-- </form>   -->
          <!-- </div> -->
          <div class="table-responsive">
            <table>
              <tr>
                <!-- <th><mat-checkbox class="example-margin" name="storeBrandData" (change)="toggleCheckboxAll($event)"></mat-checkbox></th> -->
                <th>#</th>
                <th>Staff Image</th>
                <th>Staff Name</th>


              </tr>



              <tr *ngFor="let data of calendarData; let i = index">

                <td>{{i+1}}</td>

                <td class="img_usr">
                  <figure>
                    <img [src]="data?.staffId?.image" alt="">
                  </figure>
                </td>
                <td>{{data?.staffId?.firstName}} {{data?.staffId?.lastName}}</td>
                <!-- <td>{{luxuryBrandStatus(data?.luxuryBrand)}}</td>
                  <td>{{brandStatus(data?.isActive)}}</td> -->

            </table>

            <h4 *ngIf="calendarData && calendarData.length == 0" class="text-center nt_fnd">
              No Staff Found For This Date.
            </h4>
          </div>
        </div>
      </div>

    </div>
  </div>
