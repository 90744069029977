<nav class="navbar navbar-expand navbar-dark">
  <div class="logo">
    <figure>
      <img src="assets/img/svg/logo.svg">
    </figure>
  </div>
  <a href="#menu-toggle" id="menu-toggle" class="navbar-brand"><span><i class="fa fa-bars"
        aria-hidden="true"></i></span></a> <button class="navbar-toggler" type="button" data-toggle="collapse"
    data-target="#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false"
    aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarsExample02">
    <div class="header-right">
      <div class="notification">
        <i class="fa fa-bell" aria-hidden="true" [matMenuTriggerFor]="menu1"></i>
        <mat-menu #menu1="matMenu" class="dropbox mnu_cls not_drop" xPosition="before" yPosition="below">
          <ul>
            <h4>Notifications</h4>
            <ul class="notification_list">
              <li class="ntf-list" *ngFor="let data of listOfNotification">
                <div class="ntf_dt">
                  <p class="nt_typ">{{data?.message}}</p>
                </div>
                <p class="ntf_tym">{{data.createdAt | date:'short'}}</p>
              </li>

            </ul>
            <div class="submit">
              <button class="see_link" *ngIf="listOfNotification?.length>0"
                routerLink="/pages/notification/globalnotification">See All</button>
            </div>
          </ul>
          <p *ngIf="listOfNotification?.length == 0"
            style="height: 100%; display: flex; justify-content: center; align-items: center;">
            No Notifications Found
          </p>
        </mat-menu>
      </div>
      <div class="profile">
        <div class="profile-name">
          <div class="p-img" *ngIf="role=='ADMIN'" (click)="showDropBox= !showDropBox">

            <figure>
              <img [src]="profileData?.image" onerror="this.src='assets/img/png/user-png.png'">
            </figure>
          </div>
          <div class="p-img" *ngIf="role=='PROVIDER'" (click)="showDropBox= !showDropBox">

            <figure>
              <img [src]="profileData?.image" onerror="this.src='assets/img/png/user-png.png'">
            </figure>
          </div>
          <div class="p-img" (click)="showDropBox= !showDropBox" *ngIf="role =='VENDOR'">

            <figure>
              <img [src]="profileData?.image" onerror="this.src='assets/img/png/user-png.png'">
            </figure>
          </div>
          <div class="p-img" (click)="showDropBox= !showDropBox" *ngIf="role=='INFLUENCER'">

            <figure>
              <img [src]="profileData?.image" onerror="this.src='assets/img/png/user-png.png'">
            </figure>
          </div>
          <div class="p-img" (click)="showDropBox= !showDropBox" *ngIf="role=='SUB'">

            <figure>
              <img [src]="profileData?.image" onerror="this.src='assets/img/png/user-png.png'">
            </figure>
          </div>
          <span *ngIf="role=='PROVIDER'">
            <div class="p-img" (click)="showDropBox= !showDropBox">
              <p>{{profileData?.providerName}}</p>
            </div>
            <div class="dropbox" *ngIf="showDropBox">
              <ul>
                <li routerLink="/pages/profile/profile" (click)="showDropBox=
                false">My Profile</li>
                <li routerLink="/pages/profile/changepassword" (click)="showDropBox= false">Change Password</li>
                <li (click)="logout()">Logout</li>
              </ul>
            </div>
          </span>
          <span *ngIf="role!=='PROVIDER'">
            <div class="p-img" (click)="showDropBox= !showDropBox">
              <p>{{profileData?.firstName}} {{profileData?.lastName}}</p>
            </div>
            <div class="dropbox" *ngIf="showDropBox">
              <ul>
                <li routerLink="/pages/profile/profile" (click)="showDropBox= false">
                  Profile</li>
                <li routerLink="/pages/profile/changepassword" (click)="showDropBox= false">
                  Change Password</li>
                <li (click)="logout()">Logout</li>
              </ul>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</nav>
<div id="wrapper" class="toggled">
  <!-- Sidebar -->
  <div id="sidebar-wrapper">
    <ul class="sidebar-nav">
      <ng-container *ngFor="let item of sidebarItem">
        <li *ngIf="!isSingleModule">
          <a *ngIf="item.class === 'subchild'" data-toggle="collapse" (click)="handleToggleTabs(item.path)"
            [href]="'#'+item.title.split(' ').join('_')" role="button" aria-expanded="false" aria-controls="Customer"
            class="side-menu__item">
            <div class="side-main">
              <div class="side-left">
                <figure class="sd_icn"> <img [src]="item.icon" alt=""> </figure>
                <!-- <i class="fa fa-home" aria-hidden="true"></i> -->
              </div>
              <div class="sidebar-right">
                <p> {{item.title}}</p>
              </div>
            </div>
          </a>
          <a *ngIf="item.class === 'single'" (click)="handleToggleTabs(item.path)" [routerLink]="item.path"
            routerLinkActive="active" class="side-menu__item">
            <div class="side-main">
              <div class="side-left">
                <figure class="sd_icn"> <img [src]="item.icon" alt=""> </figure>
                <!-- <i class="fa fa-home" aria-hidden="true"></i> -->
              </div>
              <div class="sidebar-right">
                <p> {{item.title}}</p>
              </div>
            </div>
          </a>
          <ng-container>
            <div class="innr_menu collapse" [id]="item.title.split(' ').join('_')">
        <li class="" *ngFor="let child of item.children">
          <a [routerLink]="child.path" (click)="handleToggleTabs(child.path)" routerLinkActive="active"
            class="side-menu__item">
            <div class="side-main">
              <div class="side-left">
                <figure class="sd_icn"> <img [src]="child.icon" alt=""
                    onerror="this.src ='assets/img/svg/catgory.svg' "> </figure>
                <!-- <i class="fa fa-home" aria-hidden="true"></i> -->
              </div>
              <div class="sidebar-right">
                <p>{{child.title}}</p>
              </div>
            </div>
          </a>
        </li>
  </div>
  </ng-container>
  </li>
  <ng-container *ngIf="isSingleModule">
    <li class="" *ngFor="let child of newSide">
      <a [routerLink]="child.path" (click)="handleToggleTabs(child.path)" routerLinkActive="active"
        class="side-menu__item">
        <div class="side-main">
          <div class="side-left">
            <figure class="sd_icn"> <img [src]="child.icon" alt="" onerror="this.src ='assets/img/svg/catgory.svg' ">
              <!-- <i class="fa fa-chevron-right" aria-hidden="true"></i> -->
            </figure>
            <!-- <i class="fa fa-home" aria-hidden="true"></i> -->
          </div>

          <div class="sidebar-right">
            <p>{{child.title}}</p>

          </div>
        </div>
      </a>
    </li>
  </ng-container>

  </ng-container>
  </ul>
</div>
<!-- Page Content -->
</div>