import { Attribute } from '@angular/compiler/public_api';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
@Component({
  selector: 'app-size-variations',
  templateUrl: './size-variations.component.html',
  styleUrls: ['./size-variations.component.scss']
})
export class SizeVariationsComponent implements OnInit {
  storeProductData:Array<any>=[];
  role: string;
  radioselectId: any;
  selectedRow: any;
  index:any
  search: string ='';
  headerValue: string = '';

  

  constructor(private dialog: MatDialogRef<SizeVariationsComponent>,private http:HttpService) {
    this.role = localStorage.getItem('role');
    this.dialog.disableClose = true;
   }
  close(){
    this.dialog.close()

  }
  ngOnInit(): void {
    this.listOfProductData()

    console.log(this.index,'iii')
  }

  listOfProductData() {
    if(this.role == 'INFLUENCER'){
      this.http.postRequest('influencer/products/web?search='+this.search + '&headerName=' +this.headerValue,'').subscribe((res) => {
        this.storeProductData = res['data']['result'];   
        
        });
    }      
  }

  selectRadio(e,data){
  console.log(e,data)
  if(e.value){
    this.selectedRow = data;
    console.log(this.selectedRow,'ssss')
  }
  }

  addproduct(){
    let attributes:any =[] 
    let products : any = []
    var parentData = {
      "attributeId" : this.selectedRow?.variations?.attributeId,
      'attributeName' : this.selectedRow?.variations?.attributeNam,
      'attributeValue' : this.selectedRow?.variations?.attributeValue,
      'attributeMeta': this.selectedRow?.variations?.attributeMeta,  
      }
      var childData = {
      "attributeId" : this.selectedRow?.variations?.child?.attributeId,
      'attributeName' : this.selectedRow?.variations?.child?.attributeName,
      'attributeValue' : this.selectedRow?.variations?.child?.attributeValue,
      'attributeMeta': this.selectedRow?.variations?.child?.attributeMeta, 
      'image':this.selectedRow?.variations?.child?.images[0]?.path
      }
      attributes.push(parentData,childData);
    var productdesc = {
    "productName" :this.selectedRow?.productName,
    "images" :this.selectedRow?.images,
    "sku" :this.selectedRow?.sku,
    "barcode" :this.selectedRow?.barcode,
    "productId" : this.selectedRow?._id,
    "vendorId" : this.selectedRow?.vendor,
    "index":this.index,
    attributes:attributes

    }
    this.dialog.close(productdesc) 
    console.log(attributes,'aaa')  
  }
  searchUnique(uniqueSearch,staticValue){
    console.log(uniqueSearch,staticValue)
    this.search = uniqueSearch
    this.headerValue = staticValue
    this.listOfProductData()
  }
 

}
