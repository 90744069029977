import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import socketIo from "socket.io-client";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
 socket: any;
 token: any;
 sessionId: any;
  constructor(  private commonService: CommonService,) {
    this.token = localStorage.getItem("accessToken");
  }

  initSocket() {
    if (this.token !== undefined) {
      this.socket = socketIo.connect(this.commonService.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: this.token,
        },
      });
      console.log(this.socket,"************* Socket *************");
    }
  }

  emitAction(action: any, payload: any): void {
    this.socket.emit(action, payload);
  }

  onEvent(event:any) {
    return Observable.create((observer) => {
      this.socket.removeListener(event);
      this.socket.on(event, (message) => {
        console.log("event", message);
        observer.next(message);
      });
    });
  }

  disconnect() {
    this.socket.disconnect();
  }
}
