import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/app';
import 'firebase/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'newproject';
  serviceWorkerAttempt: any;
  fcmDeviceToken: any;

  constructor(
    private updates: SwUpdate,
  ) {}

  ngOnInit(): void {
    this.getToken();
  }

  // for push notifications
  getToken() {
    navigator.serviceWorker.register('ngsw-worker.js');
    firebase.initializeApp(environment.firebaseConfig);
    const setInt = () => {
      navigator.serviceWorker.getRegistration().then((swr: any) => {
        this.serviceWorkerAttempt++;
        console.log(swr,'ssss')
        if (swr != undefined) {
          firebase
            .messaging()
            .getToken({ serviceWorkerRegistration: swr })
            .then((token: any) => {
              this.fcmDeviceToken = token;
              localStorage.setItem(
                'fcmDeviceToken',
                JSON.stringify(this.fcmDeviceToken)
              );
              console.log('fcmDeviceToken', this.fcmDeviceToken);
            })
            .catch((err: any) => {
              console.log('Unable to get permission to notify:', err);
            });
        } else {
          if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 3) {
            setInt();
          }
        }
      });
    };
    setInt();
  }
}
