<div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>   
<h3 *ngIf="!data"> New Staff Specialization</h3>
<h3 *ngIf="data">Update Staff Specialization</h3>
<form [formGroup]="addSpecialization">
    <div class="login-form comn_frm ad_st">
        
        <!-- <div class="form-group">
            <label class="control-label">Service Type</label>
            <mat-form-field floatLabel="never">
                <mat-select formControlName="serviceType" placeholder="Please Select">
                    <mat-option *ngFor="let ser of allServiceCategory" [value]="ser._id">
                        {{ ser?.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="submitted && f.serviceType.errors?.required">
                    Required
                </mat-error>
            </mat-form-field>
        </div> -->
           
        <div class="form-group">
            <label class="control-label">Specialization Name (En)</label>
            <input appNoSpaceAtFirst type="text" id="name"  class="form-control" formControlName="name_En"
                 />
                 <mat-error *ngIf="submitted && f.name_En.errors?.required">
                    Specialization Name (En) Required
                </mat-error>
                <mat-error *ngIf="submitted && f.name_En.errors?.maxlength">
                    Specialization Name (En)  cannot be greater than 30 character
                   
                </mat-error>
        </div>
        <div class="form-group">
            <label class="control-label">Specialization Name (Ar)</label>
            <input appNoSpaceAtFirst type="text" id="name" class="form-control" formControlName="name_Ar"
                 />
                 <mat-error *ngIf="submitted && f.name_Ar.errors?.required">
                    Specialization Name (Ar)  Required
                </mat-error>
                <mat-error *ngIf="submitted && f.name_Ar.errors?.maxlength">
                    Specialization Name (Ar)  cannot be greater than 30 character
                </mat-error>
        </div>
        <div class="btn_main cnt" *ngIf="!data">
            <button class="btn btn-primary" type="button" (click)="add()"> Save</button>
        </div>
        <div class="btn_main cnt" *ngIf="data">
            <button class="btn btn-primary" type="button" (click)="update()"> update</button>
        </div>
    </div>


</form>
</div>