import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss']
})
export class DurationComponent implements OnInit {
  durationform:FormGroup
  data:any
  submitted:boolean = false;
  time:any='15:00'
  first:any

  constructor(private dialog: MatDialog,private fb:FormBuilder,private datapipe:DatePipe,
    private http:HttpService,private cm:CommonService) { }
  close(){
    this.dialog.closeAll()

  }

  ngOnInit(): void {
    this.durationform = this.fb.group({
      duration: ['', [Validators.required,]],
      duration_Ar: ['', [Validators.required,]],
    })
    
    if(this.data){
      this.setValue();
    }
 
  }
  get f() :any{
    return this.durationform.controls
  
  }
  setValue(){
    this.durationform.patchValue(this.data)
  
  }


  add(){
    this.submitted = true;
    if(this.submitted && this.durationform.valid){
      let data1 = this.durationform.value
      this.http.postRequest('admin/duration', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Duration Added successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

  update(){
    this.submitted = true;
    if(this.submitted && this.durationform.valid){
      let data1 = this.durationform.value

      this.http.putRequest('admin/duration/'+ this.data._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Duration update successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

 

}
