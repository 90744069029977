<h2>Commission(%)</h2>
<input
  type="text"
  numbersOnly
  maxlength="10"
  [(ngModel)]="commission"
  class="form-control"
/>
<mat-error *ngIf="submitted && !commission">
  Commission Fees  is Required
</mat-error>
<div class="btn_main cnt finance" *ngIf="data">
  <button  class="btn btn-primary" type="button" (click)="update()">
    update
  </button>
</div>
