import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-influencer-expert',
  templateUrl: './influencer-expert.component.html',
  styleUrls: ['./influencer-expert.component.scss']
})
export class InfluencerExpertComponent implements OnInit {

  typeForm:FormGroup;
  id:string;
  submitted1:boolean = false
  logoImage: any;
  errorImage: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { id: string }, 
    private dialog: MatDialog,private fb :FormBuilder,private http : HttpService,
    private cm :CommonService) {
       if(this.data.id){
        console.log(data,'ddd')
        this.id = this.data.id;
        this.http.getRequestWithoutbody('admin/influencer/settings/' + this.id).subscribe((res:any) => {     
          this.typeForm.patchValue({
          name:res['data']['name'],
          name_ar:res['data']['name_ar'],       
          })
          this.logoImage=res.data.image
        })
       }
     }
  close(){
    this.dialog.closeAll()

  }
  ngOnInit(): void {
    this.typeForm = this.fb.group({
      name:['', [Validators.required,Validators.maxLength(30)]],
      name_ar:['', [Validators.required,Validators.maxLength(30)]],
    })
  }
  save(){
    this.submitted1 = true
    if(!this.id  && this.submitted1 && this.typeForm.valid && this.logoImage){
    
    let data = this.typeForm.value
    data.image = this.logoImage
    data.type = 'EXPERTISE'
    this.http.postRequest('/admin/influencer/settings', data).subscribe(
      (response: any) => {
        this.cm.presentsToast('success','top-right',"Influencer Expert Added Successfully");
        this.dialog.closeAll()
      });
    }
    else if(this.id  && this.submitted1 && this.typeForm.valid){
      console.log(this.id);
      
      let data = this.typeForm.value
      data.image = this.logoImage
      data.type = 'EXPERTISE'
      this.http.putRequest('/admin/influencer/settings/'+ this.id, data).subscribe(
        (response: any) => {
          this.cm.presentsToast('success','top-right',"Influencer Expert Updated Successfully");
          this.dialog.closeAll()
        });
    }
  }

  async uploadImage(file) {
    let formData = new FormData();
    formData.append("upload", file);
    let res = await this.http
      .postRequest('admin/upload', formData)
      .toPromise();
    return res["data"];
  }

  async fileHandler(event, imageName) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          if (imageName == "logoImage") {
            this.logoImage = event.target.result;
          }           
        };
      }
      this.errorImage = false;
      if (imageName == "logoImage") {
        this.logoImage = await this.uploadImage(File);
      }     
    } else {
      this.cm.presentsToast('error','top-right','Only png,jpg,jpeg Accepted ');
      
    }
  }
}
