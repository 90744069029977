import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberOnlyDirective } from '../shared/directives/number-only.directive';
import { CharacterOnlyDirective } from '../shared/directives/character-only.directive';
import { appNoSpaceAtFirst } from '../shared/directives/no-space.directive'
import {NumberDotOnlyDirective}    from '../shared/directives/number-dot-only.directive'
import { TextWithSpaceDirective } from "../shared/directives/text-with-space.diractive";
// import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from './material/material.module';
import { TwoDecimalPointDirective } from './directives/two-decimal-point.directive';
import {NoZeroDirective} from './directives/no-zero.directive'



@NgModule({
  declarations: [SidebarComponent, NumberOnlyDirective,CharacterOnlyDirective,appNoSpaceAtFirst,
    NumberDotOnlyDirective,TextWithSpaceDirective, TwoDecimalPointDirective,NoZeroDirective
  ],
  exports: [
    SidebarComponent,
    MatSliderModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    NumberOnlyDirective,
    CharacterOnlyDirective,
    appNoSpaceAtFirst,
    NumberDotOnlyDirective,
    TextWithSpaceDirective,
    NoZeroDirective

  ],
  imports: [
    CommonModule,
    MatSliderModule,
    MatFormFieldModule,
    RouterModule,
    MatSlideToggleModule,
    // NgxIntlTelInputModule,
    MatTabsModule,
    MatInputModule,
    MaterialModule
  ],
})
export class SharedModule {}
