<div class="mn_pp">
    <div class="mn_icn_cr" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>
    <h3 *ngIf="!data">Add New Branch</h3>
    <h3 *ngIf="data">Edit New Branch</h3>
    <form [formGroup]="addBranchForm">
        <div class="login-form comn_frm">
            <div class="form-group">
                <label class="control-label">Branch Name</label>
                <input appNoSpaceAtFirst  type="text" id="name" class="form-control" formControlName="branchName" />
                <mat-error *ngIf="submitted1 && f.branchName.errors?.required">
                    branchName is Required
                </mat-error>
                <mat-error *ngIf="submitted1 && f.branchName.errors?.maxlength">
                    branchName cannot be greater than 50 character
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Branch Name ( Ar)</label>
                <input appNoSpaceAtFirst type="text" id="name" class="form-control" formControlName="branchName_Ar" />
                <mat-error *ngIf="submitted1 && f.branchName_Ar.errors?.required">
                    branchName_Ar is Required
                </mat-error>
                <mat-error *ngIf="submitted1 && f.branchName_Ar.errors?.maxlength">
                    branchName_Ar cannot be greater than 50 character
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Branch Address</label>
                <input appNoSpaceAtFirst #location type="text" ngx-google-places-autocomplete formControlName="branchAddress"
                    (change)="onInputChange()" class="form-control" 
                    (onAddressChange)="AddressChange($event)" />
                <mat-error *ngIf="submitted1 && f.branchAddress.errors?.required">
                    Please Enter Address
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Branch Address (Ar)</label>
                <input appNoSpaceAtFirst type="text" id="name" class="form-control" formControlName="branchAddress_Ar" />
                <mat-error *ngIf="submitted1 && f.branchAddress_Ar.errors?.required">
                    branchAddress_Ar is Required
                </mat-error>
            </div>
            <div *ngIf="lat && lng" class="form-group">
                <label class="control-label">Map</label>
                <agm-map #map2 style="height: 200px;" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)">
                    </agm-marker>
                </agm-map>
            </div>



            <div class="btn_main cnt" *ngIf="!data">
                <button class="btn btn-primary" type="button" (click)="add()"> Add</button>
            </div>
            <div class="btn_main cnt" *ngIf="data">
                <button class="btn btn-primary" type="button" (click)="edit()"> Edit</button>
            </div>
        </div>


    </form>
</div>