import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-subscriptio-fees',
  templateUrl: './subscriptio-fees.component.html',
  styleUrls: ['./subscriptio-fees.component.scss']
})
export class SubscriptioFeesComponent implements OnInit {
  data:any
  subscription:string;
  submitted:boolean = false;

  constructor(private http:HttpService,private cm:CommonService,private dialog: MatDialog) { }

  ngOnInit(): void {
    if(this.data){
      console.log(this.data,'1')
      this.subscription = this.data.value;
      console.log(this.subscription,'sss')
      
    }
  }

  update(){
    this.submitted= true;
    if(this.submitted && this.subscription){
      let data1;
      data1 = {
        value:Number(this.subscription)
      }
      this.http.putRequest('admin/subscription/' + this.data._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Subscription fees update successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

}
