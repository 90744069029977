import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-influencer-type',
  templateUrl: './influencer-type.component.html',
  styleUrls: ['./influencer-type.component.scss']
})
export class InfluencerTypeComponent implements OnInit {

  typeForm:FormGroup;
  id:string;
  submitted1:boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) private data: { id: string }, 
    private dialog: MatDialog,private fb :FormBuilder,private http : HttpService,
    private cm :CommonService) {
       if(this.data.id){
        this.id = this.data.id;
        this.http.getRequestWithoutbody('admin/influencer/settings/' + this.id).subscribe((res) => {     
          this.typeForm.patchValue({
          name:res['data']['name']
          })
        })
       }
     }
  close(){
    this.dialog.closeAll()

  }
  ngOnInit(): void {
    this.typeForm = this.fb.group({
      name:['', [Validators.required,Validators.maxLength(30)]],
    })
  }
  save(){
    this.submitted1 = true
    if(!this.id && this.submitted1 && this.typeForm.valid){
    let obj = {
      name:this.typeForm.value.name,
      type:'INFLUENCER_TYPE'
    }
    this.http.postRequest('/admin/influencer/settings', obj).subscribe(
      (response: any) => {
        this.cm.presentsToast('success','top-right','Influencer Type Added Successfully');
        this.dialog.closeAll()
      });
    }
    else if(this.id && this.submitted1 && this.typeForm.valid){
      console.log(this.id);
      
      let obj = {
        name:this.typeForm.value.name,
        type:'INFLUENCER_TYPE'
      }
      this.http.putRequest('/admin/influencer/settings/'+ this.id, obj).subscribe(
        (response: any) => {
          this.cm.presentsToast('success','top-right','Influencer Type Updated Successfully');
          this.dialog.closeAll()
        });


    }

  }
  

}

