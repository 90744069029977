
<div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>     
  <h3 *ngIf="!data"> New Payment Type</h3>
  <h3 *ngIf="data"> Update Payment Type</h3>
  <form [formGroup]="vendorpaymentForm">
      <div class="login-form comn_frm ad_st">
          
          <div class="form-group">
              <label class="control-label">Payment Type</label>
              <input appNoSpaceAtFirst type="text" id="name"  class="form-control" formControlName="name"/>
              <mat-error *ngIf="submitted1 && vendorpaymentForm['controls']['name'].hasError('required')">
                Payment Type is Required
            </mat-error>
            <mat-error *ngIf="submitted1 && vendorpaymentForm['controls']['name'].hasError('maxlength')">
              Payment Type Cannot be greater than 30 Character
          </mat-error>
          </div>
        
  
  
          <div class="btn_main cnt" *ngIf="!data">
              <button class="btn btn-primary" type="button" (click)="add()"> Save</button>
          </div>
          <div class="btn_main cnt" *ngIf="data">
            <button class="btn btn-primary" type="button" (click)="update()"> Update</button>
        </div>
      </div>
  
  
  </form>
  </div>