import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-specialization',
  templateUrl: './specialization.component.html',
  styleUrls: ['./specialization.component.scss']
})
export class SpecializationComponent implements OnInit {
  data:any
  addSpecialization:FormGroup
  providerid: string;
  allServiceCategory: any;
  submitted:boolean = false

  constructor(private dialog: MatDialog,private http:HttpService,private fb:FormBuilder,private cm:CommonService) { }

  ngOnInit(): void {
    this.providerid = localStorage.getItem('providerId')

    this.addSpecialization = this.fb.group({
      // serviceType: ['', [Validators.required,]],
      name_En: ['', [Validators.required,Validators.maxLength(30)]],
      name_Ar: ['', [Validators.required,Validators.maxLength(30)]],  
      
  })
    if(this.data){
      console.log(this.data,'3')
      
      this.setValue();
    }
    
  }
  get f() :any{
    return this.addSpecialization.controls
  }

  setValue(){
    this.addSpecialization.patchValue(this.data)
  
  }
  
  close(){
    this.dialog.closeAll()

  }

  // getServiceCategoryData() {
  //   this.http.getRequest("admin/provider/services", '').subscribe(
  //     (res: any) => {

  //       this.allServiceCategory = res.data;   
  //     },
  //     (error) => console.log(error)
  //   );
  // }

  add(){
    this.submitted = true;
    if(this.submitted && this.addSpecialization.valid){
      let data1 = this.addSpecialization.value

      this.http.postRequest('admin/specialization', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Specialization Added successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

  update(){
    this.submitted = true;
    if(this.submitted && this.addSpecialization.valid){
      let data1 = this.addSpecialization.value

      this.http.putRequest('admin/specialization/'+ this.data._id, data1,).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Specialization update successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

}
