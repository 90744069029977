<div class="mn_pp">
    <div class="mn_icn_cr" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>
    <h3 *ngIf="!data"> New Duration</h3>
    <h3 *ngIf="data"> Update Duration</h3>
    <form [formGroup]="durationform" >
        <div class="login-form comn_frm ad_st">


            <!-- <mat-form-field>
                <input matInput placeholder="4:00 PM" readonly />
                <mat-icon matSuffix>
                    watch_later
                </mat-icon>
            </mat-form-field> -->
            <!-- <div class="frm-mt">
                <label class="control-label">Duration Time</label>
                <mat-form-field appearance="fill">

                    <input matTimepicker #t1="matTimepicker" i18n-okLabel="Timepicker 1 Ok Label" okLabel="Ok"
                        i18n-cancelLabel="Timepicker 1 Cancel Label" cancelLabel="Cancel" #time1="ngModel"
                        id="timepicker-example-1" ngModel placeholder="Please select time..." name="time-1" (timeChange)="timeChangeHandler($event)"  required>
                    <mat-icon matSuffix>access_time</mat-icon>
                    
                </mat-form-field>
                <mat-error *ngIf="time1.touched && time1.invalid">Invalid Date</mat-error>
            </div>
            <div class="frm-mt">
                <label class="control-label">Duration Time (Ar)</label>
                <mat-form-field appearance="fill">

                    <input matTimepicker #t1="matTimepicker" i18n-okLabel="Timepicker 1 Ok Label" okLabel="Ok"
                        i18n-cancelLabel="Timepicker 1 Cancel Label" cancelLabel="Cancel" #time1="ngModel"
                        id="timepicker-example-1" ngModel placeholder="Please select time..." name="time-1" required>
                    <mat-icon matSuffix>access_time</mat-icon>
                    
                </mat-form-field>
                <mat-error *ngIf="time1.touched && time1.invalid">Invalid Date</mat-error>
            </div> -->


            
            <div class="form-group">
                <label class="control-label">Duration Time</label>
                <input type="text" id="name" numbersOnly class="form-control" formControlName="duration" />
                <mat-error *ngIf="submitted && f.duration.errors?.required">
                    duration is Required
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Duration Time (Ar)</label>
                <input type="text" id="name" numbersOnly class="form-control" formControlName="duration_Ar" />
                <mat-error *ngIf="submitted && f.duration_Ar.errors?.required">
                    duration_Ar is Required
                </mat-error>
            </div>
            <div class="btn_main cnt" *ngIf="!data">
                <button class="btn btn-primary" type="button" (click)="add()"> Save</button>
            </div>
            <div class="btn_main cnt" *ngIf="data">
                <button class="btn btn-primary" type="button" (click)="update()"> update</button>
            </div>
        </div>


    </form>
</div>