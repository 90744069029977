<h2>Subscription Fees</h2>
<input
  type="text"
  numbersOnly
  maxlength="10"
  id="commission"
  [(ngModel)]="subscription"
  class="form-control"
/>
<mat-error *ngIf="submitted && !subscription">
 Subscription Fees is  Required
</mat-error>
<div class="btn_main cnt finance" *ngIf="data">
  <button class="btn btn-primary" type="button" (click)="update()">
    update
  </button>
</div>
