import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
declare var google: any;

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  geoCoder: any;
  formattedaddress: any = '';
  addBranchForm:FormGroup;
  submitted1:boolean = false
  lat:any
  lng:any
  data:any;
  zoom=5
  providerid: string;
  role: string;

  constructor(private dialog: MatDialog,private fb:FormBuilder,private http:HttpService,private cm:CommonService)
   {
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
       console.log(this.lat,this.lng)

    });
    }
  close(){
    this.dialog.closeAll()
  }
  ngOnInit(): void {
    console.log(this.data);
    this.role = localStorage.getItem("role")
    this.providerid = localStorage.getItem('providerId')
    this.geoCoder = new google.maps.Geocoder();
    this.addBranchForm = this.fb.group({
      branchName: ['', [Validators.required,Validators.maxLength(50)]],
      branchName_Ar: ['', [Validators.required,Validators.maxLength(50)]],
      branchAddress: ['', [Validators.required,]],
      branchAddress_Ar: ['', [Validators.required,]],    
  })
  console.log(this.lat,this.lng)
 setTimeout(() => {
  if(this.data){
    console.log(this.lat,this.lng)

    this.setValue();
  }
 }, 1000);
 
 
}
setValue(){
  this.addBranchForm.patchValue(this.data)
  if(this.data.latitude){
 this.lat = this.data.longitude;
  this.lng = this.data.latitude;
  console.log(this.lat,this.lng)
  }
 

}

get f() :any{
  return this.addBranchForm.controls
}

add(){
  if(this.role == 'ADMIN' || this.role == 'SUB'){
  this.submitted1 = true;
  if(this.submitted1 && this.addBranchForm.valid){
    let data1 = this.addBranchForm.value
    data1.latitude = this.lng
    data1.longitude = this.lat
    data1.provider = this.providerid
    this.http.postRequest('admin/provider/branch', data1).subscribe((res)=>{
      if(res['statusCode'] == 200){
        this.cm.presentsToast('success','top-right','Branch Added successfully');
         this.dialog.closeAll()
         this.cm.branchlist$.next(true)
        }
     },(err: any) => {
      console.log("err", err);
      this.cm.presentsToast('error', 'top-right', err.error.message);
    }) 
  }
  }
 else if(this.role == 'PROVIDER'){
  this.submitted1 = true;
  if(this.submitted1 && this.addBranchForm.valid){
    let data1 = this.addBranchForm.value
    data1.latitude = this.lng
    data1.longitude = this.lat
    
    this.http.postRequest('provider/branch', data1).subscribe((res)=>{
      if(res['statusCode'] == 200){
        this.cm.presentsToast('success','top-right','Branch Added successfully');
         this.dialog.closeAll()
         this.cm.branchlist$.next(true)
        }
     },(err: any) => {
      console.log("err", err);
      this.cm.presentsToast('error', 'top-right', err.error.message);
    }) 
  }
 }
}

edit(){
  if(this.role == 'ADMIN' || this.role == 'SUB'){
  this.submitted1 = true;
  if(this.submitted1 && this.addBranchForm.valid){
    let data1 = this.addBranchForm.value
    data1.latitude = this.lng
    data1.longitude = this.lat
    data1.provider = this.providerid
    this.http.putRequest('admin/provider/branch/' + this.data._id, data1).subscribe((res)=>{
      if(res['statusCode'] == 200){
        this.cm.presentsToast('success','top-right','Branch Updated successfully');
         this.dialog.closeAll()
         this.cm.branchlist$.next(true)
         
        }
     },(err: any) => {
      console.log("err", err);
      this.cm.presentsToast('error', 'top-right', err.error.message);
    })  
  }
}
else if(this.role == 'PROVIDER'){
  this.submitted1 = true
  if(this.submitted1 && this.addBranchForm.valid){
    let data1 = this.addBranchForm.value
    data1.latitude = this.lng
    data1.longitude = this.lat
    this.http.putRequest('provider/branch/' + this.data._id, data1).subscribe((res)=>{
      if(res['statusCode'] == 200){
        this.cm.presentsToast('success','top-right','Branch Updated successfully');
         this.dialog.closeAll()
         this.cm.branchlist$.next(true)
        }
     },(err: any) => {
      console.log("err", err);
      this.cm.presentsToast('error', 'top-right', err.error.message);
    })  
  }
}
}

AddressChange(address: any) {
  console.log(address,'add')
  this.formattedaddress = address.formatted_address;
  this.addBranchForm.controls.branchAddress.patchValue(address.formatted_address);
  this.lng = address.geometry.location.lng();
  this.lat = address.geometry.location.lat();
  console.log(this.lng);
  console.log(this.lat);
  
  
}
getAddress(latitude, longitude) {
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        this.addBranchForm.controls['branchAddress'].setValue(results[0].formatted_address);
      }
    }
  });
}
markerDragEnd(event){
  console.log(event);
  this.lat = event.coords.lat;
  this.lng = event.coords.lng;
  this.getAddress(event.coords.lat, event.coords.lng);
  
}

onInputChange() {
  var locationName: any = '';
  locationName = this.addBranchForm.value.branchAddress;
  if (locationName != this.formattedaddress) {
    this.addBranchForm.controls.branchAddress.reset();
    this.formattedaddress = '';
    this.lat = '';
    this.lng = '';
  }
}


}
