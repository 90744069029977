<div class="mn_pp">
  <div class="mn_icn_cr" (click)="close()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </div>
  <h3>Service Category</h3>
  <form [formGroup]="addservice">
    <div class="login-form comn_frm ad_st">

      <div class="frm_spt">
        <div class="cstm-cards">
          <div class="cstm_mg">
              <div class="sp_plc_dsh">
                  <div class="sp_adr_br">
                      <div class="sp_adr_mg">
                          <i *ngIf="!logoImage" class="fa fa-camera" aria-hidden="true"></i>
                      </div>
                      <div *ngIf="logoImage" class="image_placeholder">
                          <figure>
                            <img [src]="logoImage" alt="" />
                          </figure>
                      </div>
                      <input type="file" 
                      accept="image/*"
                      (change)="fileHandler($event, 'logoImage')"
                      >
                  </div>
              </div>
              <mat-error *ngIf="submitted1 && !logoImage" style="margin-bottom: -8px" class="invalid-feedback">
                Image is required.
            </mat-error>
             
          </div>
      </div>
      </div>

      <div class="form-group">
        <label class="control-label">SP Type Name</label>
        <input
          type="text"
          id="name"
          class="form-control"
          formControlName="name"
        />
        <mat-error *ngIf="submitted1 && f.name.errors?.required">
          SP Name Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="control-label">SP Type Name (Ar)</label>
        <input
          type="text"
          id="name"
          class="form-control"
          formControlName="name_Ar"
        />
        <mat-error *ngIf="submitted1 && f.name_Ar.errors?.required">
          SP Name Required
        </mat-error>
      </div>
      <div class="table-responsive">
        <table>
          <tr>
            <th>Status</th>
          </tr>
          <tr>
            <td>
              <li>
                <mat-slide-toggle
                  formControlName="isEnabled"
                  (change)="toggle($event)"
                >
                </mat-slide-toggle>
              </li>
            </td>
          </tr>
        </table>
      </div>

      <div class="btn_main cnt" *ngIf="isAdd">
        <button class="btn btn-primary" type="button" (click)="add()">
          Add
        </button>
      </div>
      <div class="btn_main cnt" *ngIf="isEdit">
        <button class="btn btn-primary" type="button" (click)="update()">
          Update
        </button>
      </div>
    </div>
  </form>
</div>
