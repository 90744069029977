import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import {tap} from 'rxjs/operators';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class GetinterceptorsService {
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private commonService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private modalService: NgbModal
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => {
          this.commonService.showSpinner();
          if (event instanceof HttpResponse) {
            this.commonService.hideSpinner();
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error, 'error');
          this.commonService.hideSpinner()
          if (error.error.statusCode == 401) {
            console.log('logout here');
            localStorage.clear();
            this.commonService.presentsToast(
              'error',
              'top-right',
              error.error.message
            );
            this.modalService.dismissAll();
            return this.router.navigateByUrl('auth/login');
          }
          if (error.error.statusCode == 400 || error.error.status == 400) {
            this.commonService.presentsToast('error',
              'top-right',
              error.error.message)
          }
        }
      )
    );
  }
}
