<div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>   

<h3> Color Variations</h3>
<form>
    <div class="login-form comn_frm ad_st">

        <div class="form-group">
            <label class="control-label">Color Name</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Size</label>
            <select name="cars" class="form-control" >
                <option value="option1">S</option>
                <option value="option1">M</option>
                <option value="option1">L</option>
                <option value="option1">XL</option>
            </select>
        </div>
        <div class="form-group">
            <label class="control-label">Price</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Special Price</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Special Price From	</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Special Price To	</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Seller SKU</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Stock</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Barcode</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        <div class="form-group">
            <label class="control-label">Weight</label>
            <input type="text" id="name"  class="form-control"
                 />
        </div>
        


        <div class="btn_main cnt">
            <button class="btn btn-primary"  type="button"> Add</button>
        </div>
    </div>


</form>
</div>