<div class="mn_pp">
  <div class="mn_icn_cr" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>
  <h3 *ngIf="!data">Add Service Provider Type</h3>
  <h3 *ngIf="data">Update Service Provider Type</h3>
  <form [formGroup]="addcategorization">
    <div class="login-form comn_frm ad_st">

      <div class="frm_spt">

        <div class="cstm-cards">
          <div class="cstm_mg">
              <div class="sp_plc_dsh">
                  <div class="sp_adr_br">
                      <div class="sp_adr_mg">
                          <i *ngIf="!logoImage" class="fa fa-camera" aria-hidden="true"></i>
                      </div>
                      <div *ngIf="logoImage" class="image_placeholder">
                          <figure>
                            <img [src]="logoImage" alt="" />
                          </figure>
                      </div>
                      <input type="file" 
                      accept="image/*,.svg"
                      (change)="fileHandler($event, 'logoImage')"
                      >
                  </div>
              </div>
              <mat-error *ngIf="submitted1 && !logoImage" class="invalid-feedback">
                Image is required.
            </mat-error>
             
          </div>
      </div>
      </div>

      <div class="form-group">
        <label class="control-label">SP Type Name</label>
        <input appNoSpaceAtFirst type="text" id="name" class="form-control" formControlName="name" />
        <mat-error *ngIf="submitted1 && f.name.errors?.required">
          SP Type Name  Required
      </mat-error>
      <mat-error *ngIf="submitted1 && f.name.errors?.maxlength">
        SP Type Name cannot be greater than 30 character
    </mat-error>
      </div>
      <div class="form-group">
        <label class="control-label">SP Type Name (Ar)</label>
        <input appNoSpaceAtFirst type="text" id="name" class="form-control" formControlName="name_Ar" />
        <mat-error *ngIf="submitted1 && f.name_Ar.errors?.required">
          SP Type Name (Ar) Required
      </mat-error>
      <mat-error *ngIf="submitted1 && f.name_Ar.errors?.maxlength">
        SP Type Name (Ar) cannot be greater than 30 character
    </mat-error>
      </div>
      <div class="table-responsive">
        <table>
          <tr>
            <th>Commission </th>
            <th>Status </th>          
          </tr>
          <tr>
            <td>
              <input appNoZero appNoSpaceAtFirst pattern="^\d*\.?\d{0,2}$"  type="text" id="name" class="form-control" formControlName="commission"  
              min="1" oninput="validity.valid||(value='');" 
              onKeyPress="if(this.value.length>9) return false;" />
              <mat-error *ngIf="submitted1 && f.commission.errors?.required">
                Commission is Required
            </mat-error>
            <mat-error *ngIf="submitted1 && f.commission.errors?.maxlength">
              Commission  cannot be greater than 10 character
          </mat-error>
            </td>
            <td>
              <li>
                <mat-slide-toggle formControlName="isEnabled"></mat-slide-toggle>
              </li>
            </td>          
          </tr>
        </table>
      </div>
      <div class="btn_main cnt" *ngIf="!data">
        <button class="btn btn-primary" type="button" (click)="add()"> Add</button>
      </div>
      <div class="btn_main cnt" *ngIf="data">
        <button class="btn btn-primary" type="button" (click)="update()"> Update</button>
      </div>
    </div>


  </form>
</div>