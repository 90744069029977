import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {
  allcategory: any;
  submitted: boolean = false
  addservicelist: FormGroup
  data: any
  isAdd: boolean = false;
  isEdit: boolean = false;
  allCategorization: any;
  allProvider: any= [];
  subcat: any;
  datacategory: any

  dataprovider: any
  secondLevel: any =[];
  constructor(private dialog: MatDialog, private http: HttpService, private fb: FormBuilder, private cm: CommonService) { }
  close() {
    this.dialog.closeAll()

  }
  ngOnInit(): void {
    this.addservicelist = this.fb.group({
      name: ['', [Validators.required,]],
      name_Ar: ['', [Validators.required,]],
      serviceCategory: ['', [Validators.required,]],
    })

    
      
      if(this.data){
        this.setValue()
      }
    
    console.log(this.data, 'dddd')
    console.log(this.dataprovider, 'dddd')
    this.listOfCategorization()
  }
  get f(): any {
    return this.addservicelist.controls
  }

  setValue() {
    this.addservicelist.patchValue(this.data)
    this.addservicelist.patchValue({
      serviceCategory: this.data?.serviceCategory
    })


  }


  listOfCategorization() {
    this.http.getRequestWithoutbody('admin/type').subscribe((res => {
      this.allProvider = res['data'];
      this.allProvider.filter((res=>{
        if(res._id == this.dataprovider){
          this.secondLevel = res.categories         
        }
      }))
      console.log(this.allProvider,'pppp')
    }))
  }

  add() {
    this.submitted = true;
    if (this.submitted && this.addservicelist.valid) {
      let data1 = this.addservicelist.value
      data1.serviceProviderType = this.dataprovider
      data1.serviceCategory = this.datacategory
      this.http.postRequest('admin/sub/service', data1).subscribe((res) => {
        if (res['statusCode'] == 200) {
          this.cm.presentsToast('success', 'top-right', 'Service list Added successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)

        }
      }, (err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }
      );
    }
  }

  update() {
    this.submitted = true;
    if (this.submitted && this.addservicelist.valid) {
      let data1 = this.addservicelist.value
      // data1.serviceProviderType = this.data
      this.http.putRequest('admin/sub/service/' + this.data?._id, data1).subscribe((res) => {
        if (res['statusCode'] == 200) {
          this.cm.presentsToast('success', 'top-right', 'Service list updated successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)

        }
      }, (err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }
      );
    }
  }
}

