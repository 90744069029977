<div class="mn_pp ad_stf_mn">
    <div class="mn_icn_cr" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>

    <h3 *ngIf="!data"> New staff</h3>
    <h3 *ngIf="data"> Update staff</h3>
    <form [formGroup]="addStaffForm">
        <div class="login-form comn_frm ad_st">
            <div class="cstm-cards">
                <div class="cstm_mg">
                    <div class="sp_plc_dsh">
                        <div class="sp_adr_br">
                            <div class="sp_adr_mg">
                                <i class="fa fa-camera" aria-hidden="true"></i>
                            </div>
                            <div *ngIf="logoImage" class="image_placeholder">
                                <figure>
                                    
                                  <img [src]="logoImage" alt="" />
                                </figure>
                            </div>
                            <!-- <p >Add Image</p> -->
                            <input type="file" 
                            accept="image/*"
                            (change)="fileHandler($event)"
                            >
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-group">
                <label class="control-label">First Name(En)</label>
                <input type="text" id="name" class="form-control" formControlName="firstName" />
                <mat-error *ngIf=" submitted && f.firstName.errors?.required">
                    First Name(En) Required
                </mat-error>
                <mat-error *ngIf=" submitted && f.firstName.errors?.maxlength">
                    First Name(En) cannot be greater than 50 Character
                </mat-error>
            </div>
            
            <div class="form-group">
                <label class="control-label">Last Name(En)</label>
                <input type="text" id="name" class="form-control" formControlName="lastName" />
            </div>
            <div class="form-group">
                <label class="control-label">First Name(Ar)</label>
                <input type="text" id="name" class="form-control" formControlName="firstName_ar" />
                <mat-error *ngIf="submitted && f.firstName_ar.errors?.required">
                    First Name(Ar) Required
                </mat-error>
                <mat-error *ngIf="submitted && f.firstName_ar.errors?.maxlength">
                    First Name(Ar) cannot be greater than 50 Character
                </mat-error>
            </div>
            
            <div class="form-group">
                <label class="control-label">Last Name(Ar)</label>
                <input type="text" id="name" class="form-control" formControlName="lastName_ar" />
            </div>

            <div class="form-group">
                <label class="control-label">Specialization </label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="specialization" placeholder="Please Select">
                        <mat-option *ngFor="let spe of allspecialization" [value]="spe._id">
                            {{ spe?.name_En }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="submitted && f.specialization.errors?.required">
                    Specialization is Required
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Services provided </label>
                <mat-form-field floatLabel="never">
                    <mat-select multiple formControlName="services" placeholder="Please Select">
                        <mat-option *ngFor="let pro of allprovider" [value]="pro._id">
                            {{ pro?.name }}
                        </mat-option>
                    </mat-select>                  
                </mat-form-field>
                <mat-error *ngIf="submitted && f.services.errors?.required">
                    Services provided is Required
                </mat-error>
            </div>
            <div class="form-group dy_hr">
                <label class="control-label">Working Hours</label>
                <ul class="wrkg  card-body">
                    <li class="wkg_lt" *ngFor="let data of timeSlots;let i = index">
                        <span class="wr_dy">{{data?.day}}</span>
                        <span class="wr_tim">
                            <div class="dtm-right mt-3">
                                <mat-label>Start Time </mat-label>
                                <input class="form-control" type="time" name="appt-time" value="00:00" (change)="startTime($event,data)" [(ngModel)]="timeSlots[i].start" [ngModelOptions]="{standalone: true}"/>
                               
                            </div>
                              <div class="dtm-right mt-3" style="display: flex;">
                                <mat-label>End Time </mat-label>
                                <input class="form-control" type="time" (change)="endTime($event,data,i)" [(ngModel)]="timeSlots[i].end" [ngModelOptions]="{standalone: true}" />
                                
                              </div>
                        </span>
                        <span class="wr_tgl" *ngIf="timeSlots[i].isView">
                            <mat-slide-toggle
                            [(ngModel)]="timeSlots[i].isEnabled"
                           
                            [ngModelOptions]="{standalone: true}"
                            
                          ></mat-slide-toggle>
                        </span>
                    </li>
                    
                </ul>

            </div>

            <div class="form-group">
                <!-- <label class="control-label">Add Staff</label> -->
                <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1">
                <div ngxSlickItem *ngFor="let slide of slides1" class="slide">
                    <div class="sp_ctg_pt" routerLink="/shop/product">
                        <a href="javascript:void">
                        <figure> <img [src]="slide.img" alt="Image"> </figure>
                        <p>{{slide.text}}</p>
                    </a>
                    </div>
                
                </div>
            </ngx-slick-carousel> -->
            </div>

            <div class="btn_main cnt" *ngIf="!data">
                <button class="btn btn-primary" type="button" (click)="add()"> Add</button>
            </div>

            <div class="btn_main cnt" *ngIf="data">
                <button class="btn btn-primary" type="button" (click)="update()"> Update</button>
            </div>
        </div>


    </form>
</div>