import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-service-provider-type',
  templateUrl: './service-provider-type.component.html',
  styleUrls: ['./service-provider-type.component.scss']
})
export class ServiceProviderTypeComponent implements OnInit {
  errorImage: boolean;
  submitted1:boolean = false
  addcategorization:FormGroup
  logoImage: any;
  t: any;
  data:any
  constructor(private dialog: MatDialog,private http:HttpService,private fb:FormBuilder,private cm:CommonService,private router:Router) { }
  close(){
    this.dialog.closeAll()

  }

  ngOnInit(): void {
    this.addcategorization = this.fb.group({
      name: ['', [Validators.required,Validators.maxLength(30)]],
      name_Ar: ['', [Validators.required,Validators.maxLength(30)]],
      commission: ['', [Validators.required,Validators.maxLength(10)]],
      isEnabled:false
})
if(this.data){
  this.setValue();
}
  }

  get f() :any{
    return this.addcategorization.controls
  }
  setValue(){
    this.addcategorization.patchValue(this.data)
    this.logoImage=this.data.logo
  
  }

  async uploadImage(file) {
    let formData = new FormData();
    formData.append("upload", file);
    let res = await this.http
      .postRequest('admin/upload', formData)
      .toPromise();
    return res["data"];
  }

  async fileHandler(event, imageName) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg" ||
      File.type == "image/svg+xml"||
      File.type == "image/svg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          if (imageName == "logoImage") {
            this.logoImage = event.target.result;
          } 
          
        };
      }
      this.errorImage = false;
      if (imageName == "logoImage") {
        this.logoImage = await this.uploadImage(File);
      } 
     
    } else {
      this.errorImage = true;
      this.cm.presentsToast('error', 'top-right', 'Only PNG, JPEG ,JPG are Allowed');
    }
  }

  add(){
    this.submitted1 = true;
    if(this.submitted1 && this.addcategorization.valid && this.logoImage){
      let data1 = this.addcategorization.value
      data1.logo = this.logoImage     
      this.http.postRequest('admin/type', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Provider Added successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );

    }
  }

  update(){
    this.submitted1 = true;
    if(this.submitted1 && this.addcategorization.valid && this.logoImage){
      let data1 = this.addcategorization.value
      data1.logo = this.logoImage
      this.http.putRequest('admin/type/'+ this.data?._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Provider Updated successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );
    }
  }


}
