
   <div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>   
<h3> New Subscription Type</h3>
<form [formGroup]="addSubscriptionForm">
    <div class="login-form comn_frm ad_st">
        
        <div class="form-group">
            <label class="control-label">Subscription type</label>
            <input type="text" id="name" appNoSpaceAtFirst  class="form-control" formControlName="name"
                 /> 
                 <mat-error *ngIf="submitted && f.name.errors?.required">
                    Subscription Type Required
                </mat-error>
                <mat-error *ngIf="submitted && f.name.errors?.maxlength">
                    Session Name cannot be greater than 50 character
                </mat-error>
        </div>
        <div class="btn_main cnt" *ngIf="!data">
            <button class="btn btn-primary" type="button" (click)="add()"> Save</button>
        </div>
        <div class="btn_main cnt" *ngIf="data">
            <button class="btn btn-primary" type="button" (click)="UPDATE()"> Update</button>
        </div>
    </div>


</form>
</div>