import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  addSubscriptionForm:FormGroup
  data:any
  submitted:boolean = false
  constructor(private dialog: MatDialog,private fb:FormBuilder,private http:HttpService,private cm:CommonService) { }

  ngOnInit(): void {

    this.addSubscriptionForm = this.fb.group({
      name: ['', [Validators.required,Validators.maxLength(50)]],   
  })

    if(this.data){
      this.setValue();
    }
  }
  get f() :any{
    return this.addSubscriptionForm.controls
  }
  setValue(){
    this.addSubscriptionForm.patchValue(this.data)
  
  }
  close(){
    this.dialog.closeAll()

  }

  add(){
    this.submitted = true;
    if(this.submitted && this.addSubscriptionForm.valid){
      let data1 = this.addSubscriptionForm.value

      this.http.postRequest('admin/subscription', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Subscription Added successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

  UPDATE(){
    this.submitted = true;
    if(this.submitted && this.addSubscriptionForm.valid){
      let data1 = this.addSubscriptionForm.value

      this.http.putRequest('admin/subscription/'+ this.data._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Subscription update successfully');
          this.dialog.closeAll()
          this.cm.providerSetting$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }) 
    }
  }

}
