<div class="mn_pp"> 
    <div class="mn_icn_cr" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>     
  <h3 *ngIf="!data">New Vendor Type</h3>
  <h3 *ngIf="data">Update Vendor Type</h3>
  <form [formGroup]="vendorTypeForm">
      <div class="login-form comn_frm ad_st">
          
          <div class="form-group">
              <label class="control-label">Vendor Type</label>
              <input appNoSpaceAtFirst type="text" class="form-control" formControlName="name"/>
              <mat-error *ngIf="submitted1 && vendorTypeForm['controls']['name'].hasError('required')">
                Vendor Type is Required
            </mat-error>
            <mat-error *ngIf="submitted1 && vendorTypeForm['controls']['name'].hasError('maxlength')">
                Vendor Type Cannot be greater than 30 Character
            </mat-error>
          </div>
        
  
  
          <div class="btn_main cnt" *ngIf="!data">
              <button class="btn btn-primary" type="button" (click)="add()">Save</button>
          </div>
          <div class="btn_main cnt" *ngIf="data">
            <button class="btn btn-primary" type="button" (click)="update()"> update</button>
        </div>
      </div>
  
  
  </form>
  </div>