<div class="mn_pp">
    <div class="mn_icn_cr" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>
    <h3> Influencer Expert </h3>
    <form [formGroup]="typeForm">
        <div class="login-form comn_frm ad_st">

            <div class="frm_spt">

                <div class="cstm-cards">
                    <div class="cstm_mg ifusr">
                        <div class="sp_plc_dsh">
                            <div class="sp_adr_br">
                                <div class="sp_adr_mg">
                                    <i class="fa fa-camera" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="logoImage" class="image_placeholder">
                                    <figure>
                                        <img [src]="logoImage" alt="" />
                                    </figure>
                                </div>
                                <input type="file" accept="image/*" (change)="fileHandler($event, 'logoImage')">
                            </div>
                            <mat-error
                            *ngIf="submitted1 && !logoImage"
                            class="invalid-feedback"
                          >
                            Image is required.
                          </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label">Influencer Expert(En)</label>
                <input appNoSpaceAtFirst type="text" id="name" formControlName="name" class="form-control" />
                <mat-error *ngIf="submitted1 && typeForm['controls']['name'].hasError('required')">
                    Influencer Expert(En) is Required
                </mat-error>
                <mat-error *ngIf="submitted1 && typeForm['controls']['name'].hasError('maxlength')">
                    Influencer Expert(En) Cannot be greater than 30 character
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Influencer Expert(Ar)</label>
                <input appNoSpaceAtFirst type="text" id="name" formControlName="name_ar" class="form-control" />
                <mat-error *ngIf="submitted1 && typeForm['controls']['name_ar'].hasError('required')">
                    Influencer Expert(Ar) is Required
                </mat-error>
                <mat-error *ngIf="submitted1 && typeForm['controls']['name_ar'].hasError('maxlength')">
                    Influencer Expert(Ar) Cannot be greater than 30 character
                </mat-error>
            </div>
            <div class="btn_main cnt">
                <button class="btn btn-primary" type="button" (click)="save()"> Save</button>
            </div>
        </div>
    </form>
</div>