import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-addpayment',
  templateUrl: './addpayment.component.html',
  styleUrls: ['./addpayment.component.scss']
})
export class AddpaymentComponent implements OnInit {
  calendar: any;
  formattedDate: any = Date;
  calendarData: any;
  dashboarddate:any
  convertedDate:any
  apiDate: string;
  c: string;
  staffData: any;
  constructor(private datePipe: DatePipe,@Inject(MAT_DIALOG_DATA) private data: { date: any }
  ,private dialog: MatDialog,private cm:CommonService,private http:HttpService,) { }
  close(){
    this.dialog.closeAll()

  }

  ngOnInit(): void {
  console.log('Selected date:', this.data.date);
  this.dashboarddate = this.data.date
  console.log(this.dashboarddate,'ddd')
  const originalDate = new Date(this.dashboarddate);
  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();
  this.convertedDate = `${month}-${day}-${year}`;
  console.log(this.convertedDate,'ccc')



  this.getProviderCalendar()
  }

  getProviderCalendar(){
    this.http.httpGetWithHeader('provider/calender?type=day' + "&date=" + this.convertedDate,'').subscribe((res: any) => {
    this.calendarData = res.data;
    console.log(this.calendarData)
    });
  }

}
