import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { GetinterceptorsService } from './services/interceptor/getinterceptors.service';
import { apiList } from './services/apilist';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BranchComponent } from './modals/branch/branch.component';
import { NewServiceComponent } from './modals/new-service/new-service.component';
import { AddStaffComponent } from './modals/add-staff/add-staff.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AddpaymentComponent } from './modals/addpayment/addpayment.component';
import { ServiceProviderTypeComponent } from './modals/service-provider-type/service-provider-type.component';
import { DurationComponent } from './modals/duration/duration.component';
import { SubscriptionComponent } from './modals/subscription/subscription.component';
import { SpecializationComponent } from './modals/specialization/specialization.component';
import { VendorTypeComponent } from './modals/vendor-type/vendor-type.component';
import { VendorGroupComponent } from './modals/vendor-group/vendor-group.component';
import { PaymentTypeComponent } from './modals/payment-type/payment-type.component';
import { ShippingTypeComponent } from './modals/shipping-type/shipping-type.component';
import { CourierNameComponent } from './modals/courier-name/courier-name.component';
import { InfluencerTypeComponent } from './modals/influencer-type/influencer-type.component';
import { InfluencerGroupComponent } from './modals/influencer-group/influencer-group.component';
import { RejectionReasonsComponent } from './modals/rejection-reasons/rejection-reasons.component';
import { CommissionComponent } from './modals/commission/commission.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { NgxPaginationModule } from 'ngx-pagination';
import { ColoVariationsComponent } from './modals/colo-variations/colo-variations.component';
import { SizeVariationsComponent } from './modals/size-variations/size-variations.component';
import { ServiceCategoryComponent } from './modals/service-category/service-category.component';
import { ServiceListComponent } from './modals/service-list/service-list.component';
import { InfluencerExpertComponent } from './modals/influencer-expert/influencer-expert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from './shared/material/material.module';
import { MatInputModule } from '@angular/material/input';
import { NumberOnlyDirective } from './shared/directives/number-only.directive';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatTimepickerModule } from 'mat-timepicker';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { MatSelectFilterModule } from 'mat-select-filter';
import { SubscriptioFeesComponent } from './modals/subscriptio-fees/subscriptio-fees.component';
import { ExportComponentComponent } from './modals/export-component/export-component.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({
  declarations: [
    AppComponent,
    BranchComponent,
    NewServiceComponent,
    AddStaffComponent,
    AddpaymentComponent,
    ServiceProviderTypeComponent,
    DurationComponent,
    SubscriptionComponent,
    SpecializationComponent,
    VendorTypeComponent,
    VendorGroupComponent,
    PaymentTypeComponent,
    ShippingTypeComponent,
    CourierNameComponent,
    InfluencerTypeComponent,
    InfluencerGroupComponent,
    RejectionReasonsComponent,
    CommissionComponent,
    ColoVariationsComponent,
    SizeVariationsComponent,
    ServiceCategoryComponent,
    ServiceListComponent,
    InfluencerExpertComponent,
    SubscriptioFeesComponent,
    ExportComponentComponent,
  ],
  imports: [
    MatFormFieldModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    SlickCarouselModule,
    NgxPaginationModule,
    GooglePlaceModule,
    MatTimepickerModule,
    MatSelectFilterModule,
    NgxSpinnerModule,
    MatInputModule,
    MatTooltipModule,
    SharedModule,
    AgmCoreModule.forRoot({
      libraries: ['drawing', 'places', 'geometry'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable'
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      scope: "/firebase-cloud-messaging-push-scope"
    }),
  ],
  providers: [
    DatePipe,
    NgxSpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetinterceptorsService,
      multi: true,
    },
    { provide: MatDialogRef, useValue: {} },
    apiList,

    // Constant
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
