
<div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>     
  <h3> New Rejection Reasons</h3>
  <form [formGroup]="typeForm">
      <div class="login-form comn_frm ad_st">
          
          <div class="form-group">
              <label class="control-label">Rejection Reasons</label>
              <input  appNoSpaceAtFirst type="text" id="name" formControlName="name" class="form-control"/>
              <mat-error *ngIf="submitted1 && typeForm['controls']['name'].hasError('required')">
                Rejection Reason is Required
            </mat-error>
            <mat-error *ngIf="submitted1 && typeForm['controls']['name'].hasError('maxlength')">
                Rejection Reason Cannot be greater than 100 Character
            </mat-error>
          </div>
          <div class="btn_main cnt">
              <button class="btn btn-primary" type="button" (click)="save()"> Save</button>
          </div>
      </div>
  
  
  </form>
  </div>