
<div class="mn_pp"> 
    <div class="mn_icn_cr"  (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>     
  <h3 *ngIf="!data"> New Shipping Type</h3>
  <h3 *ngIf="data"> Update Shipping Type</h3>
  <form [formGroup]="shippingtypeForm">
      <div class="login-form comn_frm ad_st">
          
          <div class="form-group">
              <label class="control-label">Shipping Type</label>
              <input type="text" appNoSpaceAtFirst id="name"  class="form-control" formControlName="name" />
              <mat-error *ngIf="submitted1 && shippingtypeForm['controls']['name'].hasError('required')">
                shipping Type is Required
            </mat-error>
            <mat-error *ngIf="submitted1 && shippingtypeForm['controls']['name'].hasError('maxlength')">
              shipping Type Cannot be greater than 30 Character
          </mat-error>
          </div>
        
  
  
          <div class="btn_main cnt" *ngIf="!data">
              <button class="btn btn-primary" type="button" (click)="add()"> Save</button>
          </div>
          <div class="btn_main cnt" *ngIf="data">
            <button class="btn btn-primary" type="button" (click)="update()"> Update</button>
        </div>
      </div>
  
  
  </form>
  </div>