import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss']
})
export class PaymentTypeComponent implements OnInit {

  url:any
  data:any
  vendorpaymentForm:FormGroup
  submitted1:boolean = false;

  constructor(private dialog: MatDialog,
    private http:HttpService,
    private fb:FormBuilder,
    private cm:CommonService,
    private router:Router) {
    this.createForm();
   }

  ngOnInit(): void {
    if(this.data){
      this.setValue();
    }
  }
  setValue(){
    this.vendorpaymentForm.patchValue(this.data)
  
  }

  createForm(){
    this.vendorpaymentForm = this.fb.group({
      name: ['', [Validators.required,Validators.maxLength(30)]],
    });
  }

  close(){
    this.dialog.closeAll()

  }

  add(){
    this.submitted1 = true;
    console.log("vendor",this.vendorpaymentForm);
    
    if(this.submitted1 && this.vendorpaymentForm.valid){
      let data1 = this.vendorpaymentForm.value
      data1.type = 'PAYMENT_TYPE'
      this.http.postRequest('admin/vendor/settings',data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Payment Type Added successfully');
          this.dialog.closeAll()
          this.cm.vendorSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );
      }
  }

  
  update(){
    this.submitted1 = true;
    console.log("vendor",this.vendorpaymentForm);
    
    if(this.submitted1 && this.vendorpaymentForm.valid){
      let data1 = this.vendorpaymentForm.value
      data1.type = 'PAYMENT_TYPE'
      this.http.putRequest('admin/vendor/settings/'+ this.data?._id,data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Payment Type Updated successfully');
          this.dialog.closeAll()
          this.cm.vendorSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );
      }
  }

}
