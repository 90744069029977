import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.scss']
})
export class ServiceCategoryComponent implements OnInit {
  errorImage: boolean;
  submitted1:boolean = false
  addservice:FormGroup
  logoImage: any;
  t: any;
  data:any;
  isAdd:boolean=false;
  isEdit:boolean=false;
  constructor(private dialog: MatDialog,private http:HttpService,private fb:FormBuilder,private cm:CommonService,private router:Router) { }
  close(){
    this.dialog.closeAll()

  }

  ngOnInit(): void {
    this.addservice = this.fb.group({
      name: ['', [Validators.required,]],
      name_Ar: ['', [Validators.required,]],
      isEnabled:['']
})
if(this.data){
  this.setValue();
  console.log(typeof(this.data)=='string')
  console.log(typeof(this.data)=='boolean')

  if(typeof(this.data)=='string'){
this.isAdd = true;
  }
  if(typeof(this.data)=='object'){
    this.isEdit = true;
  }
}
  }

  get f() :any{
    return this.addservice.controls
  }
  setValue(){
    this.addservice.patchValue(this.data)
    this.logoImage=this.data.logo
  
  }

  async uploadImage(file) {
    let formData = new FormData();
    formData.append("upload", file);
    let res = await this.http
      .postRequest('admin/upload', formData)
      .toPromise();
    return res["data"];
  }

  async fileHandler(event, imageName) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          if (imageName == "logoImage") {
            this.logoImage = event.target.result;
          } 
          
        };
      }
      this.errorImage = false;
      if (imageName == "logoImage") {
        this.logoImage = await this.uploadImage(File);
      } 
     
    } else {
      this.errorImage = true;
      // this.toastr.errorToastr("Only PNG, JPEG ,JPG are Allowed");
    }
  }

  add(){
    this.submitted1 = true;
    if(this.submitted1 && this.addservice.valid && this.logoImage){
      let data1 = this.addservice.value
      data1.logo = this.logoImage
      data1.isEnabled = this.t
      data1.serviceProviderType = this.data

      console.log(data1,'pro')
      this.http.postRequest('admin/service/category', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Provider Added successfully');
           this.dialog.closeAll()
           this.cm.providerSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );

    }
  }

  update(){
    this.submitted1 = true;
    if(this.submitted1 && this.addservice.valid && this.logoImage){
      let data1 = this.addservice.value
      data1.logo = this.logoImage
      data1.isEnabled = this.t
      console.log(data1,'pro')
      this.http.putRequest('admin/service/category/'+ this.data?._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Provider Updated successfully');
           this.dialog.closeAll()
           this.cm.providerSetting$.next(true)
          }
        },(err: any) => {
          console.log("err", err);
          this.cm.presentsToast('error', 'top-right', err.error.message);
        }
        );

    }
  }

  toggle(e){
   console.log(e.checked)
   this.t = e.checked
  }

}
