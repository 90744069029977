import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiList } from './apilist';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrl: string = environment.baseUrl;
  adminDetails: any;
  encryptedBody:any;

  constructor(private http: HttpClient, private apiList: apiList,private commonService:CommonService) {}

  httpPost(url: string, params): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }
  httpPostForPasswordReset(url: string, code: any, params): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '/' + code,

      params
    );
  }

  httpPostWithHeader(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.header()
    );
  }
  httpGetWithHeaderById(url: string, id: any): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '/' + id,
      this.header()
    );
  }
  httpPostWithHeaderAndBody(url: string, id, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      this.header()
    );
  }

  httpPostWithHeaderFormData(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.formDataHeader()
    );
  }

  httpPutWithHeaderFormData(url: string, id, params: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      this.formDataHeader()
    );
  }

  httpPutWithHeaderAndParam(url: string, params: any) {
    return this.http.put(
      this.baseUrl + url,
      params,
      this.header()
    );
  }

  httpPostWithHeaderFormDataById(
    url: string,
    id,
    params: any
  ): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      this.formDataHeader()
    );
  }

  httpPutWithHeader(url: string, id, params: any): Observable<Object> {
    let q = params ? '/' : '';
    return this.http.put(

      this.baseUrl + url + q + params,
      params,
      this.header()
    );
  }

  httpDeleteHeader(url: string, params: any): Observable<Object> {
    let q = params ? '/' : '';
    return this.http.delete(
      this.baseUrl + url + q + params,
      this.header()
    );
  }

  httpGetWithHeader(url: string, params): Observable<Object> {
    let q = params ? '/' : '';
    return this.http.get(
      this.baseUrl + url + q + params,
      this.header()
    );
  }

  httpGetWithQuery(url: string, params, page: any): Observable<Object> {
    let q = params ? '/' : '';
    return this.http.get(
      this.baseUrl + url + q + params,
      this.header()
    );
  }
  httpputWithQuery(url: string, params, pageNo: any): Observable<Object> {
    let q = params ? '/' : '';
    return this.http.put(
       this.baseUrl + url + q + params,
      this.header()
    );
  }

  httpGetWithIdAndQuery(url: string, params): Observable<Object> {
    return this.http.get(
      this.baseUrl + url + '?' + params,
      this.header()
    );
  }

  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }

  httpGetWithHeaderOnly(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url], this.header());
  }

  httpGetWithSearch(url: string, param: any): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '?' + param,
      this.header()
    );
  }

  httpGetSearchWithId(url: string, id: any, param: any): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '/' + id + '?' + param,
      this.header()
    );
  }


  httpPostWithFormDataHeaderAndBody(url: any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      param,
      this.formDataHeader()
    );
  }

  httpGetWithHeaderforForm(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + url);
  }
  httppost(url: any, param: any): Observable<Object> {
    
    return this.http.post(this.baseUrl + url, param);
  }
  getRequest(endPoint, reqBody) {
    return this.http.get(`${this.baseUrl}${endPoint}`, reqBody);
  }
  getRequestById(endPoint, reqBody) {
    return this.http.get(`${this.baseUrl}${endPoint}`);
  }
  getRequestWithoutbody(endPoint) {
    return this.http.get(`${this.baseUrl}${endPoint}`);
  }
  // postRequest(endPoint, reqBody) {
  //   this.encryptedBody = this.commonService.encryptData(reqBody)
  //   return this.http.post(`${this.baseUrl}${endPoint}`, reqBody);
  // }
  postRequest(endPoint: string, reqBody: any) {
    if (endPoint === 'admin/upload' || endPoint === 'vendor/upload' || endPoint === 'provider/upload' || endPoint === 'influencer/upload' || endPoint === 'admin/product/import') {
      this.encryptedBody = reqBody;
    } else  {
      this.encryptedBody = this.commonService.encryptData(reqBody)
    }
    return this.http.post(`${this.baseUrl}${endPoint}`, this.encryptedBody);
  }
  postRequestById(endPoint, reqBody) {
    this.encryptedBody = this.commonService.encryptData(reqBody)
    return this.http.post(`${this.baseUrl}${endPoint}`, this.encryptedBody);
  }

  putRequest(endPoint, reqBody) {
    this.encryptedBody = this.commonService.encryptData(reqBody)
    return this.http.put(`${this.baseUrl}${endPoint}`, this.encryptedBody);
  }


  deleteRequest(endPoint, reqBody) {
    return this.http.delete(`${this.baseUrl}${endPoint}`, reqBody);
  }

  header() {
    if (
      localStorage.Authorization != undefined ||
      localStorage.Authorization != null
    ) {
      const headers = new HttpHeaders({
        'cache-control': 'no-cache',
        'content-type': 'application/json',
        Authorization: localStorage.Authorization,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  formDataHeader() {
    if (
      localStorage.Authorization != undefined ||
      localStorage.Authorization != null
    ) {
      const headers = new HttpHeaders({
        mimeType: 'multipart/form-data',
        Authorization: localStorage.Authorization,
      });
      const option = {
        headers,
      };
      return option;
    }
  }
}
