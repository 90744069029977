import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import * as dayjs from 'dayjs';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // socketUrl = "https://devapi.strutoo.com/";
  socketUrl = "https://api.strutoo.com/";
  iv = CryptoJS.enc.Base64.parse('9MSkTNVhJcWedegR'); //giving empty initialization vector
  key = CryptoJS.SHA256('MKOIJNQASDFVRGHNBHUCFTXDRESZWA'); //hashing the key using SHA256
  encryptedString: any
  imageFlag:Subject<any>=new Subject<any>();
  $isOrderId:Subject<string>=new Subject<string>();
  isApiHit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $status:Subject<any>=new Subject<any>();
  $calendarStatus:Subject<any>=new Subject<any>();

  constructor(public location: Location,private spinner: NgxSpinnerService) {}

  presentsToast(type: any, position: any, message: any,data?:any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      width: 400,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
    const toastConfig: any = {
      title: message,
      icon: type,
    };
    
    if (data) {
      toastConfig.html = data;
    }
  
    Toast.fire(toastConfig);
  }
  backButton(){
    history.back();
  }
  vendorSetting$:Subject<boolean> = new Subject<boolean>();
  providerSetting$:Subject<boolean> = new Subject<boolean>();
  stafflist$:Subject<boolean> = new Subject<boolean>()
  stafflistProvider$:Subject<boolean> = new Subject<boolean>()
  servicelist$:Subject<boolean> = new Subject<boolean>()
  branchlist$:Subject<boolean> = new Subject<boolean>()
  spSettingCategoy$:Subject<boolean> = new Subject<boolean>()

  confirmToast() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  openDialog(type, message) {
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: type,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Close',
    });
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  noSpace(event) {
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }
  validateImageFile(fileData) {
    if (!(fileData == '' || fileData == null || fileData == undefined)) {
      if (fileData.type == 'image/jpeg' ||
        fileData.type == 'image/png' ||
        fileData.type == 'image/jpg') {
        return true;
      } else {
        this.presentsToast('error', 'Please upload a valid image file!','');
        return false;
      }
    }
  }
  validateVideoFile(fileData) {
    if (fileData.type == 'video/mp4' || fileData.type == 'video/mkv' || fileData.type == 'video/mov' || fileData.type == 'video/wmv' || fileData.type == 'video/flv' || fileData.type == 'video/avi' || fileData.type == 'video/avchd') {
      return true;
    } else {
      this.presentsToast('error','top-end','Only video is accepted');
      return false;
    }
  }

  showSpinner() {
    // console.log("show spinner");
    this.spinner.show();
  }

  hideSpinner() {
    // console.log("hide spinner");
    this.spinner.hide();
  }

  handleAddress(event: any) {
    console.log("evebt",event);
    let city: any;
    let state: any;
    let country: any;
    let address:any=event['formatted_address'];
    event.address_components.forEach((element: any) => {
      if (element.types[0] == 'locality') {
        city = element.long_name;
      }
      if (element.types[0] == 'administrative_area_level_1') {
        state = element.long_name;
      }
      if (element.types[0] == 'country') {
        country = element.long_name;
      }
    });
    const latitude = event.geometry.location.lat();
    const longitude = event.geometry.location.lng();
     return { city, state, country, latitude, longitude,address };
  }

  getEncryption(data: any) {
    data = data.slice();
    this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  return this.encryptedString.toString();
}
encryptData(data:any){
  const currentTimestamp = dayjs().valueOf();
  data['appkey']=currentTimestamp;
    this.encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  return {
    hash: this.encryptedString.toString(),
  };
}
 
}
